<template>
    <div id="schecontainer">
     <div id="back">
  <a class="backbutton" href="/taskmanager">{{symbol}}任务面板</a>  
</div>
    <div class="scheduler-manager">
      <h2>定时任务管理</h2>
  
      <!-- 显示定时任务表格 -->
      <a-table :dataSource="tasks" rowKey="schedulecode" bordered>
    <a-table-column title="任务描述" dataIndex="task" key="task" />
    <a-table-column title="Cron 表达式" dataIndex="cron_expression" key="cron_expression" />
    <a-table-column title="下次运行时间" dataIndex="next_run" key="next_run" />
    <a-table-column title="状态" key="status" align="center">
      <!-- 使用 record 来获取行数据 -->
      <template #default="{ record }">
        <a-tooltip :title="record?.is_enabled ? '运行中' : '禁用'">
          <span :class="record?.is_enabled ? 'status-dot enabled' : 'status-dot disabled'"></span>
        </a-tooltip>
      </template>
    </a-table-column>
    <a-table-column title="操作" key="actions" align="center">
      <template #default="{ record }">
        <a-button @click="editTask(record)" type="link">编辑</a-button>
        <a-button @click="deleteTask(record.schedulecode)" type="link" danger>删除</a-button>
      </template>
    </a-table-column>
  </a-table>
  
      <!-- 无任务时的提示 -->
      <div v-if="tasks.length === 0">
        <p>暂无定时任务</p>
      </div>
  
      <!-- 弹出表单的模态框 -->
      <a-modal v-model:visible="isModalVisible" title="编辑定时任务" @ok="updatescheTask" @cancel="handleCancel">
        <a-form>
          <a-form-item label="任务描述">
            <a-input v-model:value="editingTask.task" />
          </a-form-item>
  
          <a-form-item label="Cron 表达式">
            <a-input v-model:value="editingTask.cron_expression" />
          </a-form-item>
  
          <a-form-item label="调度代码">
            <a-input v-model:value="editingTask.schedulecode" />
          </a-form-item>
  
          <a-form-item>
            <a-checkbox v-model:checked="editingTask.is_enabled">启用任务</a-checkbox>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    </div>

  </template>
  
  
  <script>
  import { ref, reactive, onMounted } from 'vue';
  import axios from 'axios';
  import configure_options from '../configure';
  import { notification } from 'ant-design-vue';
  export default {
    setup() {
  // 创建响应式数据
  const tasks = ref([]); // 任务列表
  const isModalVisible = ref(false); // 控制模态框显示/隐藏
  const testva = ref("fuckyou"); // 示例变量
  const editingTask = reactive({
    task: 'no content',
    CronExpression: '',
    Schedulecode: '',
    IsEnabled: false,
  }); // 编辑的任务

  // 获取任务列表
  const fetchScheduledTasks = () => {
    var env = configure_options["env"];
    var serverhost = configure_options["app_setting"][env]["serverhost"];
    axios.get(serverhost + '/v1/getschelist')
      .then(response => {
        if (response.data.scheduledTasks) {
          tasks.value = response.data.scheduledTasks;
        } else {
          console.log('没有定时任务');
          tasks.value=[]
        }
      })
      .catch(error => {
        console.error("获取定时任务失败:", error);
      });
  };

  // 编辑任务
  const editTask = (task) => {
    console.log("🧿🧿🧿🧿🧿🧿");
    console.log(task);
    
    // 直接赋值到 reactive 对象中
    Object.assign(editingTask, task);
    console.log(editingTask);
    isModalVisible.value = true; // 显示模态框
  };

  // 更新任务
  const updatescheTask = () => {
    var env = configure_options["env"];
    var serverhost = configure_options["app_setting"][env]["serverhost"];
    console.log("⬇️⬇️⬇️⬇️");
    console.log(editingTask);
    axios.post(serverhost + '/v1/updatescheTask', editingTask)
      .then(response => {
        console.log("任务更新成功", response.data);
        fetchScheduledTasks(); // 刷新任务列表
        isModalVisible.value = false; // 关闭模态框
      })
      .catch(error => {
        console.error("任务更新失败", error);
      });
  };

  // 取消编辑
  const handleCancel = () => {
    isModalVisible.value = false; // 关闭模态框
  };

  // 删除任务
  const deleteTask = (schedulecode) => {
    if (confirm("确定要删除此任务吗？")) {
      var env = configure_options["env"];
      var serverhost = configure_options["app_setting"][env]["serverhost"];
      axios.post(serverhost + '/v1/deletesche', { "schedulecode": schedulecode })
        .then(response => {
          console.log(response.data.message);
          
     
          notification.success({
  message: '成功通知',
  description: '已经成功删除该定时任务。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: '#333', // 字体颜色
  },
});
fetchScheduledTasks(); // 重新获取任务列表
        })
        .catch(error => {
            console.log(error)
            notification.error({
  message: '错误通知',
  description: '当前该服务不可用。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
         
        });
    }
  };

  // 在组件挂载时调用
  onMounted(() => {
    fetchScheduledTasks(); // 组件加载时获取任务列表
  });

  return {
    tasks,
    isModalVisible,
    testva,
    editingTask,
    fetchScheduledTasks,
    editTask,
    updatescheTask,
    handleCancel,
    deleteTask,
  };
}

  };
  </script>
  
  <style scoped>
  .scheduler-manager {
    max-width: 80vw;
    min-width: 50vw;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    font-family:"KaiTi";
  }
  
  .task-list {
    margin-bottom: 20px;
  }
  
  .task-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .task-actions button {
    margin-left: 10px;
  }

  .status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

/* 启用状态的绿色点 */
.status-dot.enabled {
  background-color: green;
}

/* 禁用状态的红色点 */
.status-dot.disabled {
  background-color: red;
}

#schecontainer{
    display: flex;
    flex-direction: column;
}

#back{
    margin-left: auto;
}
  </style>
  